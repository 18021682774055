import {SiteStore} from '@wix/wixstores-client-core/dist/es/src/viewer-script/site-store/siteStore';
import {PageMap, Topology} from '@wix/wixstores-client-core/dist/es/src/constants';
import {productWithVariantsMapper} from '@wix/wixstores-platform-common/dist/src/products/product.mapper';
import {IControllerErrorReporter} from '@wix/wixstores-client-core/dist/es/src/viewer-script/createViewerScript';
import {query as getFirstProductQuery} from '../graphql/getFirstProduct.graphql';
import {query as slugQuery} from '../graphql/getProductBySlug.graphql';
import {query as storeMetaDataQuery} from '../graphql/getStoreMetaData.graphql';
import {graphqlOperation, GRAPHQL_SOURCE} from '../constants';
import {parseUrl} from '@wix/native-components-infra/dist/src/urlUtils';
import {GetFirstProductQueryVariables, GetProductBySlugQueryVariables} from '../graphql/queries-schema';
import {SPECS} from '../specs';

export function gqlStoreFrontQuery(siteStore: SiteStore, query: string, variables: {}, operationName: string) {
  return siteStore.httpClient.post(siteStore.resolveAbsoluteUrl(`/${Topology.STOREFRONT_GRAPHQL_URL}`), {
    query,
    variables,
    source: GRAPHQL_SOURCE,
    operationName,
  });
}

export function gqlQuery(siteStore: SiteStore, query: string, variables: {}, operationName: string) {
  return siteStore.httpClient.post(siteStore.resolveAbsoluteUrl(`/${Topology.READ_WRITE_GRAPHQL_URL}`), {
    query,
    variables,
    source: GRAPHQL_SOURCE,
    operationName,
  });
}

export function getProductBySlug(siteStore: SiteStore, slug: string, externalId: string) {
  const productsQueryVariables: GetProductBySlugQueryVariables = {
    slug,
    externalId,
    withSubscriptionInfo: siteStore.experiments.enabled(SPECS.SUBSCRIPTION_PLANS),
    withAltText: siteStore.experiments.enabled(SPECS.SUPPORT_ALT_TEXT),
    withPricePerUnit: siteStore.experiments.enabled(SPECS.SELLING_IN_UNITS_SF),
    withCountryCodes: siteStore.experiments.enabled(SPECS.CASHIER_EXPRESS_IN_PRODUCT_PAGE),
  };

  const query = slugQuery;

  return gqlStoreFrontQuery(siteStore, query, productsQueryVariables, graphqlOperation.GetProductBySlug);
}

export function getDefaultProduct(siteStore: SiteStore, externalId: string) {
  const productsQueryVariables: GetFirstProductQueryVariables = {
    limit: 1,
    sort: {sortField: 'CreationDate', direction: 'Descending'},
    filters: null,
    externalId,
    withSubscriptionInfo: siteStore.experiments.enabled(SPECS.SUBSCRIPTION_PLANS),
    withAltText: siteStore.experiments.enabled(SPECS.SUPPORT_ALT_TEXT),
    withPricePerUnit: siteStore.experiments.enabled(SPECS.SELLING_IN_UNITS_SF),
  };

  const query = getFirstProductQuery;

  return gqlStoreFrontQuery(siteStore, query, productsQueryVariables, graphqlOperation.GetDefaultProduct);
}

export function getStoreMetaData(siteStore: SiteStore) {
  const query = storeMetaDataQuery;

  return gqlQuery(
    siteStore,
    query,
    {withPickupOnly: siteStore.experiments.enabled(SPECS.SUPPORT_PICKUP_ONLY)},
    graphqlOperation.GetStoreMetaData
  );
}

/* istanbul ignore next: todo: test */
const fetchProduct = async (siteStore: SiteStore, isDefault: boolean = false) => {
  const encodedProductName = siteStore.location.path[siteStore.location.path.length - 1];
  const productName = decodeURIComponent(encodedProductName);
  const query = {
    method: 'post',
    headers: {Authorization: siteStore.instanceManager.getInstance()},
    body: JSON.stringify({
      query: {
        filter: JSON.stringify({slug: productName}),
      },
      includeVariants: true,
    }),
  };
  if (isDefault) {
    query.body = JSON.stringify({
      query: {
        sort: JSON.stringify([{numericId: 'desc'}]),
        paging: {limit: 1, offset: 0},
      },
      includeVariants: true,
    });
  }
  const response = await fetch(siteStore.resolveAbsoluteUrl(`/_api/catalog-server/api/v1/products/query`), query);
  const json = await response.json();
  return json.products && json.products[0];
};

export async function WixCodeGetProductBySlag(siteStore: SiteStore) {
  const product = await fetchProduct(siteStore, false);
  /* istanbul ignore else: todo: test */
  if (product) {
    return productWithVariantsMapper(product);
  } else {
    throw new Error('product not found');
  }
}

export function getUrlWithoutParams(url: string): string {
  const parsedUrl = parseUrl(url);
  return `${parsedUrl.protocol}://${parsedUrl.host}${parsedUrl.path}`;
}

export async function wixCodeGetProduct(siteStore: SiteStore, reportError: IControllerErrorReporter) {
  try {
    const currentUrl = getUrlWithoutParams(siteStore.location.url);
    const sectionUrl = (await siteStore.getSectionUrl(PageMap.PRODUCT)).url;
    if (
      (siteStore.isPreviewMode() && siteStore.location.path.length > 1) ||
      (siteStore.isSiteMode() && currentUrl !== sectionUrl)
    ) {
      return await WixCodeGetProductBySlag(siteStore);
    } else {
      const defaultProduct = await fetchProduct(siteStore, true);
      /* istanbul ignore else */
      if (defaultProduct) {
        return productWithVariantsMapper(defaultProduct);
      } else {
        throw new Error('product not found');
      }
    }
  } catch (e) {
    /* istanbul ignore next */
    reportError(e);
  }
}
